<template>
  <div>
    <v-card
      class="px-6 pb-4"
      elevation="2"
      color="primary"
      rounded="lg"
    >
      <v-tabs
        v-model="tabs"
        right
        background-color="primary"
        dark
      >
        <v-tab key="1">
          <v-icon left>mdi-leaf-circle-outline</v-icon>
          Debit Note
        </v-tab>
        <v-tab
          v-if="debitInfo.searchingType === 'BILL_LADING_NO'"
          key="2"
        >
          <v-icon left>mdi-bullseye</v-icon>
          {{ $t('text-notice-of-arrival') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tabs"
        class="mt-2 rounded-lg"
      >
        <v-tab-item
          key="1"
          eager
        >
          <v-row
            no-gutters
            align="center"
            class="white--text mb-2"
          >
            <v-spacer />
            <v-btn
              :href="`${debitUrl}&download=1`"
              class="mt-2 mr-4"
              color="success"
              :ripple="false"
              download
              small
              tag="a"
            >
              <v-icon
                left
                small
                >mdi-download</v-icon
              >
              {{ $t('button-download') }}
            </v-btn>
          </v-row>
          <iframe
            :src="debitUrl"
            style="border: 1px solid #666ccc"
            title="Debit PDF Previewer"
            frameborder="1"
            scrolling="auto"
            height="800"
            width="100%"
          ></iframe>
        </v-tab-item>
        <v-tab-item
          key="2"
          eager
        >
          <v-row
            no-gutters
            align="center"
            class="white--text mb-2"
          >
            <v-spacer />
            <v-btn
              :href="`${noaUrl}&download=1`"
              class="mt-2 mr-4"
              color="success"
              :ripple="false"
              download
              small
              tag="a"
            >
              <v-icon
                left
                small
                >mdi-download</v-icon
              >
              {{ $t('button-download') }}
            </v-btn>
          </v-row>
          <iframe
            v-if="debitInfo.availableFiles.includes('NOA')"
            :src="noaUrl"
            style="border: 1px solid #666ccc"
            title="NOA PDF Previewer"
            frameborder="1"
            scrolling="auto"
            height="800"
            width="100%"
          ></iframe>
          <NoData
            v-else
            text="Dữ liệu đang được cập nhật..."
            min-height="800px"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NoData from '@/components/shared/local/base/NoData.vue';

export default {
  props: {
    searchNo: String,
  },
  components: { NoData },
  data: () => ({
    dialog: {},
    tabs: 'tab-2',
  }),
  computed: {
    ...mapState('vsl/debit', ['debitInfo']),
    debitUrl() {
      return `${process.env.VUE_APP_VSL_API}/v1/debits/files?recipient_id=${this.debitInfo.recipientId}&file_type=DEBIT`;
    },
    noaUrl() {
      return `${process.env.VUE_APP_VSL_API}/v1/debits/files?recipient_id=${this.debitInfo.recipientId}&file_type=NOA`;
    },
  },

  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
:deep {
  .v-stepper__label {
    width: 100%;
  }
}
</style>
